<template>
  <div class="main-frame">
    <div class="title-404">404</div>
    <h1>お探しのページは見つかりませんでした</h1>
  </div>
</template>

<script>
</script>

<style scoped>
.main-frame{
  padding-top: 500px;
}
.title-404{

}
</style>